import { Card, Typography } from "@mui/material";
import React, { useState } from "react";
import { RenderConfirmDialog } from "../../../components/ConfirmationBox";
import CartItemEditModal from "../../CartItemEditModal";
import PartsNumberSpinner from "./PartsNumberSpinner";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const PartCartItem = (props) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { removeItem, cartItems, setCartItems, item, handleSnack } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  return (
    <div>
      <RenderConfirmDialog
        confimationOpen={confirmationOpen}
        message={`Pressing 'Yes' will remove the cart item!`}
        handleNo={() => setConfirmationOpen(false)}
        handleYes={() => {
          removeItem(item.id);
          setConfirmationOpen(false);
        }}
      />
      <CartItemEditModal
        show={show}
        handleClose={handleClose}
        item={item}
        setItem={setCartItems}
        handleSnack={handleSnack}
      />
      <Card
        elevation={2}
        sx={{
          p: 2,
          backgroundColor: "#f5f5f5",
          borderRadius: 3,
          borderColor: " #872ff7 ",
        }}
        variant="outlined"
      >
        <div className="row" style={{ alignItems: "center" }}>
          <div className="row col-md-12 ml-2">
            <div className="col-md-9">
              <Typography fontSize={16} fontWeight={500}>
                {item?.partNumber}
              </Typography>
              <Typography fontSize={13} className="mb-0 mt-2">
                {item?.partDescription}
              </Typography>
            </div>
            <div className="col-md-3">
              <Typography variant="h6">
                {formatter.format(item?.listPrice * item?.quantity)}
              </Typography>
            </div>
            <div className="row col-md-12 mt-4">
              <div className="col-sm-6 col-md-2">
                {" "}
                <PartsNumberSpinner
                  portfolioCartId={item.id}
                  qnt={item.quantity}
                  setCartItems={setCartItems}
                  cartItems={cartItems}
                  steps={1}
                  setConfirmationOpen={setConfirmationOpen}
                />
              </div>
              <div className="col-md-2 col-sm-6">
                <button
                  onClick={() => setConfirmationOpen(true)}
                  className="btn bg-primary text-white remove-button"
                  style={{ fontSize: 12, height: 26 }}
                >
                  Remove
                </button>
              </div>
              {/* <div className="col-md-2 col-sm-6">
                <button
                  onClick={() => setShow(true)}
                  className="btn bg-primary text-white remove-button"
                  style={{ fontSize: 12, height: 26 }}
                >
                  Configure
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PartCartItem;
