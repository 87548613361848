import React, { useState } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "react-select";

const reasonForRequestOptions = [
  { value: "accountUnlock", label: "Account unlock" },
  { value: "grantAccess", label: "Grant access" },
  { value: "buildNewUseCase", label: "Build new use case" },
  { value: "coDevelopUseCase", label: "Co-develop use case" },
  { value: "traning", label: "Training " },
  { value: "systemDefect", label: "System defect" },
  { value: "productSupport", label: "Product support" },
];

const AccountHelp = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <>
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid mt-3">
          <h4 className="mt-5">Account</h4>
          <div className="card  mt-3 p-3">
            <div className="row mt-3">
              <div className="col-md-4 col-sm-4">
                <div className="form-group">
                  <label className="font-size-14 " for="exampleInputEmail1">
                    REASON FOR REQUEST
                  </label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={reasonForRequestOptions}
                    placeholder="Account unlock"
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="form-group ">
                  <label
                    class="text-light-dark font-size-14 font-weight-500"
                    for="exampleInputEmail1"
                  >
                    DESCRIPTION(Short description)
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Placeholder (Optional)"
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="form-group ">
                  <label
                    class="text-light-dark font-size-14 font-weight-500"
                    for="exampleInputEmail1"
                  >
                    BRIEF DESCRIPTION(long description)
                  </label>
                  <div>
                    <TextareaAutosize
                      className="w-100 form-control"
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Placeholder (Optional)"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group ">
                  <label
                    class="text-light-dark font-size-14 font-weight-500"
                    for="exampleInputEmail1"
                  >
                    USER NAME
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Defaulted by system"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group ">
                  <label
                    class="text-light-dark font-size-14 font-weight-500"
                    for="exampleInputEmail1"
                  >
                    EMAIL (if not same in profile)
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Placeholder (Optional)"
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="form-group ">
                  <label
                    class="text-light-dark font-size-14 font-weight-500"
                    for="exampleInputEmail1"
                  >
                    CONTACT (if not same in profile)
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Placeholder (Optional)"
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12">
                <div className="Add-new-segment-div p-3 border-radius-10 bg-light-blue">
                  <a href="#" className="btn bg-primary text-white">
                    <span className="mr-2">+</span>Add Attachments
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountHelp;
