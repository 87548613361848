export const login = "/login";
export const equipment = "/equipment";
export const orderHistory = "/order-history";
export const claimHistory = "/claim-history";
export const equipmentMasterComp = "/eqipment-master";
export const warrantyAdministration = "/warranty-master";
export const claimAdministration = "/claim-master";
export const manageWarrantyReturns = "/warranty-return";
export const help = "/help";
export const partsSearch = "/parts-search";
