import React, { useEffect, useState } from "react";

import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import { Box, Divider } from "@mui/material";

import { Modal } from "react-bootstrap";

import {
  notesList,
  warrantyBasisOptions,
  warrantyCategoryOptions,
  warrantyStatusOptions,
  warrantyUnitOptions,
} from "./WarrantyContstants";
import { isEmpty } from "./common/textUtilities";
import { getFormatDateTime } from "./common/dateUtilities";
import { fetchWarranty } from "../services/EquipmentService";
import WarrantyClaimAddUpdate from "./WrrantyClaimAddUpdate";

const WarrantyOverView = ({
  show,
  hideModal,
  recordId,
  warranty,
  handleSnack,
}) => {
  const [warrantyRecord, setWarrantyRecord] = useState({
    ...warranty,
  });
  const [showClaimDetailsModal, setShowClaimDetailsModal] = useState(false);
  const [showClaimAddEditModal, setShowClaimAddEditModal] = useState(false);

  const handleShowClaimAddEditModal = () => {
    setShowClaimAddEditModal(!showClaimAddEditModal);
    // setShowClaimBackModal(!showClaimAddEditModal);
  };
  useEffect(() => {
    if (recordId && recordId !== null) {
      fetchWarranty(recordId)
        .then((warranty) => {
          console.log(warranty);
          const { installerDetails, customerDetails, ...responseData } =
            warranty;

          // get category key value pairs
          const _category = warrantyCategoryOptions.find(
            (obj) => obj.value === responseData.category
          );

          // get basis key value pairs
          const _basis = warrantyBasisOptions.find(
            (obj) => obj.value === responseData.basis
          );

          // get unit key value pairs
          const _unit = warrantyUnitOptions.find(
            (obj) => obj.value === responseData.unit
          );

          // get status key value pairs
          const _warrantyStatus = warrantyStatusOptions.find(
            (obj) => obj.value === responseData.warrantyStatus
          );

          // set Overview record
          setWarrantyRecord({
            ...responseData,
            category: _category || "",
            basis: _basis || "",
            unit: _unit || "",
            warrantyStatus: _warrantyStatus || "",
          });
        })
        .catch((e) => console.log(e));
    }
  }, [recordId]);

  // Overview
  const overview = () => {
    return (
      <>
        {showClaimAddEditModal && (
          <WarrantyClaimAddUpdate
            show={showClaimAddEditModal}
            hideModal={handleShowClaimAddEditModal}
            warranty={warranty}
            handleSnack={handleSnack}
          />
        )}
        <div className="row">
          <div className="col-md-10">
            <h5>WARRANTY DETAILS</h5>
          </div>
          <div className="col-md-2 justify-content-end mb-2 float-end ">
            <button
              className="btn btn-primary float-end "
              onClick={handleShowClaimAddEditModal}
            >
              Raise Claim
            </button>
          </div>
        </div>
        <Divider />
        <div className="row mt-3 mb-5">
          <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 border-50">
            {/* <div className="card border px-5">
              <img
                src="../../assets/images/spare-parts.png"
                alt="spare-parts"
                className="card-img-top width-75 img-fluid"
              />
            </div> */}

            <div className="card-body">
              <h6 className="card-title">WARRANTY AND COVERAGE INFORMATION</h6>
              <p class="card-text">
                Get to know about our warranty offerings for each product by
                contacting your OEM / authorized dealer. The applicable Gainwell
                Limited Warranty and coverage details may vary depending on the
                product type, application, and location. Additional coverage
                options may be available for attachments, Certified Rebuild,
                Used and Certified Used Equipment. Limitations may apply.
              </p>
              <p>
                Registering your warranty ensures that you receive important
                information about potential recalls and helps us process claims
                quickly. Your OEM / dealer will usually register your warranty.
              </p>
              <h6>
                Need to register your engine or OEM Solutions product warranty?
                Register your product today.
              </h6>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 equipment-master-chart mt-custom">
            <div className="">
              <div className="bg-white p-3 border-radius-10 ">
                <div className="d-flex align-items-center justify-content-between equipment-pagination">
                  <h5 className="font-weight-600 mb-0 text-uppercase">
                    {/* CARGADOR RUEDAS/PORTAH. INTEGR - 992K */}
                    {isEmpty(warrantyRecord.title)
                      ? "NA"
                      : warrantyRecord.title}
                  </h5>
                </div>
              </div>

              <>
                <div className="bg-white p-3 border-radius-10 overflow-hidden">
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Warranty Id
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.warrantyId)
                            ? "NA"
                            : warrantyRecord.warrantyId} */}
                            18
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Category
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.category?.label)
                            ? "NA"
                            : warrantyRecord.category?.label} */}
                          STANDARD
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Basis
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.basis?.label)
                            ? "NA"
                            : warrantyRecord.basis?.label} */}
                          TIME
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Unit
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.unit?.label)
                            ? "NA"
                            : warrantyRecord.unit?.label} */}
                          MONTHS
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Warranty Start Date
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.warrantyStartDate)
                            ? "NA"
                            : getFormatDateTime(
                                warrantyRecord.warrantyStartDate,
                                false
                              )} */}
                          12/01/2024
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Warranty End Date
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.warrantyEndDate)
                            ? "NA"
                            : getFormatDateTime(
                                warrantyRecord.warrantyEndDate,
                                false
                              )} */}
                          11/01/2026
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Warranty Start Usage
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.warrantyStartUsage)
                            ? "NA"
                            : warrantyRecord.warrantyStartUsage} */}
                          NA
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Warranty End Usage
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.warrantyEndUsage)
                            ? "NA"
                            : warrantyRecord.warrantyEndUsage} */}
                          NA
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Model Number
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.modelNumber)
                            ? "NA"
                            : warrantyRecord.modelNumber} */}
                          992K
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Make
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.make)
                            ? "NA"
                            : warrantyRecord.make} */}
                          CATERPILLER
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Machine/Component
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {warrantyRecord.machine ? "Yes" : "No"} */}
                          MACHINE
                        </h6>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Machine Serial Number
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {isEmpty(warrantyRecord.serialNumber)
                            ? "NA"
                            : warrantyRecord.serialNumber}
                        </h6>
                      </div>
                    </div> */}
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Component Code
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.componentNumber)
                            ? "NA"
                            : warrantyRecord.componentNumber} */}
                          NA
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Serial Number
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.serialNumber)
                            ? "NA"
                            : warrantyRecord.serialNumber} */}
                          ZMX00507
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Date of Install
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.dateOfInstall)
                            ? "NA"
                            : getFormatDateTime(
                                warrantyRecord.dateOfInstall,
                                false
                              )} */}
                          12/01/2024
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Warranty Certificate
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.warrantyCertificate)
                            ? "NA"
                            : warrantyRecord.warrantyCertificate} */}
                          NA
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Proof of Install
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.proofOfInstall)
                            ? "No"
                            : "Yes"}
                          <InsertPhotoIcon /> */}
                          NO
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                      <div className="form-group">
                        <p className="text-light-dark font-size-12 font-weight-500 mb-1">
                          Warranty Status
                        </p>
                        <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                          {/* {isEmpty(warrantyRecord.warrantyStatus?.label)
                            ? "NA"
                            : warrantyRecord.warrantyStatus?.label} */}
                          IN WARRANTY
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-end">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <h5 style={{ fontWeight: "bold" }}>Disclaimer</h5>
                    <div className="card border px-3">
                      <ul className="py-2">
                        <li className="text-light-dark font-size-17 font-weight-500 mb-2">
                          Warranty Agreement
                        </li>
                        <ol className="list-group-numbered" type="a">
                          {notesList.map((notes, i) => (
                            <li
                              key={`notes-${i}`}
                              className="text-light-dark font-size-17 font-weight-500 mb-2"
                            >
                              {`${i + 1}. ${notes.title}`}
                              <ul>
                                {isEmpty(notes.subTitle) &&
                                  notes.contentList.map((content, j) => (
                                    <li
                                      key={`notes-content-${i}-${j}`}
                                      className="text-light-dark font-size-14 font-weight-500 mb-2"
                                    >
                                      {content}
                                    </li>
                                  ))}
                                {!isEmpty(notes.subTitle) && (
                                  <>
                                    <li className="text-light-dark font-size-14 font-weight-500 mb-2">
                                      {notes.subTitle}
                                    </li>
                                    <ol
                                      className="pl-3"
                                      type="a"
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {notes.contentList.map((content, j) => (
                                        <li
                                          key={`notes-content-with-subTitle-${i}-${j}`}
                                          className="text-light-dark font-size-14 font-weight-500 mb-2"
                                        >
                                          {content}
                                        </li>
                                      ))}
                                    </ol>
                                  </>
                                )}
                              </ul>
                            </li>
                          ))}
                        </ol>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal show={show} onHide={hideModal} size="xl">
        <Modal.Body>
          <Box sx={{ typography: "body1" }}>{overview()}</Box>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WarrantyOverView;
