import { createContext, useState } from "react";

export const PartsContext = createContext();

const PartsProvider = ({ children }) => {
  const [cartParts, setCartParts] = useState([]);
  return (
    <PartsContext.Provider value={{ cartParts, setCartParts }}>
      {children}
    </PartsContext.Provider>
  );
};
export default PartsProvider;