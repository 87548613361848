import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import FormGroup from "@mui/material/FormGroup";
import { FormControlLabel, Switch } from "@mui/material";
import { addClaim } from "../services/EquipmentService";


const WarrantyClaimAddUpdate = ({ show, hideModal, warranty, viewClaim, handleSnack }) => {
    const [claimRecord, setClaimRecord] = useState({ ...warranty });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClaimRecord({ ...claimRecord, [name]: value });
    };

    console.log("claimRecord :: ", claimRecord);
    const createClaim = () => {
        const newClaim = {
            "claimNumber": Math.floor(Math.random() * 90000) + 10000,
            "modelNumber": "992K",
            "equipmentNumber": "ZMX00507",
            "serialNumber": "ZMX00507",
            "componentCode": "",
            "claimStatus": "REGISTERED",
            "claimType": "STANDARD",
            // "modelNumber": claimRecord.modelNumber,
            // "equipmentNumber": claimRecord.equipmentNumber,
            // "serialNumber": claimRecord.serialNumber,
            // "componentCode": claimRecord.componentNumber,
            // "claimStatus": "REGISTERED",
            // "claimType": "STANDARD",
            // // "failDate": "2024-01-30",
            // // "failurePartNumber": "string",
            // // "hourOnMachine": 0,
            // // "hoursOnFailedPart": 0,
            // // "uploadPhoto": "string",
            // // "partList": "string",
            // // "repairTime": 0,
            // // "claimStory": "string",
            "claimNotes": claimRecord.claimNotes,
           // // "claimQuestionnaire": "string",
            "payer": "CUSTOMER",
            "claimApprover": "NA",
            "claimReceiptDate": new Date(),
            "createdOn": new Date(),
            // "payer": "CUSTOMER",
            // "claimApprover": "string",
            // "claimReceiptDate": new Date(),
            // "createdOn": new Date(),
            // // "updatedOn": new Date(),
            // // "createdDate": new Date(),
            // // "closedDate": "2024-01-30",
            // // "appoverRejectedOn": "2024-01-30",
            "warranty": {
                // "warrantyId": claimRecord.warrantyId
                "warrantyId": 18
            },
            "replacement": true
            // "warranty": {
            //     "warrantyId": claimRecord.warrantyId
            // },
            // "replacement": true
        }
        addClaim(newClaim).then(createdClaim => {
            handleSnack('success', `Claim number ${createdClaim["claimNumber"]} has been raised!`)
            hideModal();
        }).catch(e => {
            console.log(e);
        })
    }

    return (
        <>
            <Modal show={show} onHide={hideModal} size="xl">
                <Modal.Body>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 border-50 text-white bg-primary">
                            <div className="card py-4 px-2 text-white bg-primary align-items-center ">
                                <h2 className="text-white">Warranty Claim</h2>
                                <h5 className="text-white">Provided Information</h5>
                                <div
                                    className="row px-2 d-flex flex-column justify-content-center align-items-center"
                                    style={{ borderLeft: "1px solid #ffffff" }}
                                >
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="d-block">
                                            <p className="text-light-60 text-white font-size-12 m-0 font-weight-500">
                                                Warranty Status
                                            </p>
                                            <p className="text-white font-size-14 mb-1 font-weight-500 text-uppercase">
                                                {claimRecord.warrantyStatus}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="d-block">
                                            <p className="text-light-60 text-white font-size-12 m-0 font-weight-500">
                                                Warranty ID
                                            </p>
                                            <p className="text-white font-size-14 mb-1 font-weight-500 text-uppercase">
                                                {claimRecord.warrantyNumber}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="d-block">
                                            <p className="text-light-60 text-white font-size-12 m-0 font-weight-500">
                                                Serial Number
                                            </p>
                                            <p className="text-white font-size-14 mb-1 font-weight-500 text-uppercase">
                                                {claimRecord.serialNumber}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="d-block">
                                            <p className="text-light-60 text-white text-white font-size-12 m-0 font-weight-500">
                                                Model Number
                                            </p>
                                            <p className="text-white font-size-14 mb-1 font-weight-500 text-uppercase">
                                                {claimRecord.modelNumber}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="d-block">
                                            <p className="text-light-60 text-white font-size-12 m-0 font-weight-500">
                                                Replacement
                                            </p>
                                            <p className="text-white font-size-14 mb-1 font-weight-500 text-uppercase">
                                                {claimRecord.replacement ? "YES" : "NO"}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="d-block">
                                            <p className="text-light-60 text-white font-size-12 m-0 font-weight-500">
                                                Installed
                                            </p>
                                            <p className="text-white font-size-14 mb-1 font-weight-500 text-uppercase">
                                                Yes
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="d-block">
                                            <p className="text-light-60 text-white font-size-12 m-0 font-weight-100">
                                                Distributor Info
                                            </p>
                                            <p className="text-white font-size-14 mb-1 font-weight-500 text-uppercase">
                                                <AssignmentTurnedInIcon />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 border-50">
                            <h5>Warranty Details</h5>

                            <div className="card px-2 py-3 border">
                                <div className="row">
                                    {viewClaim && <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                CLAIM NUMBER
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.claimNumber}
                                            </h6>
                                        </div>
                                    </div>}
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Model Number
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.modelNumber} */}
                                                992K
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Equipment Number
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.equipmentNumber} */}
                                                zmx00507
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Serial Number
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.serialNumber} */}
                                                ZMX00507
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Component Code
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.componentNumber} */}
                                                NA
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label className="font-size-14 font-weight-500">
                                                Warranty End Date
                                            </label>
                                            <p className="text-primary font-size-14 mt-1 font-weight-500 text-uppercase">
                                                {/* {claimRecord.warrantyEndDate} */}
                                                11/01/2026
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label className="font-size-14 font-weight-500">
                                                Warranty End Usage
                                            </label>
                                            <p className="text-primary font-size-14 mt-1 font-weight-500 text-uppercase">
                                                {/* {claimRecord.warrantyEndUsage} */}
                                                NA
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <label className="font-size-14 font-weight-500">
                                                Installed On
                                            </label>
                                            <p className="text-primary font-size-14 mt-1 font-weight-500 text-uppercase">
                                                {claimRecord.dateOfInstall}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <FormGroup>
                                                <FormControlLabel
                                                    style={{ alignItems: "start", marginLeft: 0 }}
                                                    control={
                                                        <Switch
                                                            checked={claimRecord.replacement}
                                                            onChange={(e) =>
                                                                setClaimRecord({
                                                                    ...claimRecord,
                                                                    replacement: e.target.checked,
                                                                })
                                                            }
                                                        />
                                                    }
                                                    labelPlacement="top"
                                                    label={
                                                        <p className="font-size-12 font-weight-500 text-uppercase">
                                                            Replacement
                                                        </p>
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>





                                    {!viewClaim &&
                                        <div className="col-sm-12 col-12">
                                            <div className="form-group">
                                                <label className="font-size-14 font-weight-500">
                                                    Claim Notes
                                                </label>
                                                <textarea
                                                    name="claimNotes"
                                                    cols="30"
                                                    rows="3"
                                                    value={claimRecord.claimNotes}
                                                    onChange={handleInputChange}
                                                    // placeholder="Claim Note"
                                                    className="form-control border-radius-10 text-primary"
                                                ></textarea>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                            <h5>Customer Details</h5>
                            <div className="card px-2 py-3 border">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Customer Id
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.customerNumber} */}
                                                101211
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Customer Name
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.customerName} */}
                                                KOOLAN IRON ORE PTY LTD
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Email
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.email} */}
                                                www.mtgibsoniron.com.au
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Address
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.address} */}
                                                L 12, Kings Park Rd
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                City
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.city} */}
                                                KINGS PARK RD
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                State
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.state} */}
                                                WEST PERTH
                                            </h6>
                                        </div>
                                    </div>


                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Country
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.country} */}
                                                AUSTRALIA
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Zipcode
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.zipCode} */}
                                                6005
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Phone
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {/* {claimRecord.customerDetails?.phoneNumber} */}
                                                +619658632420
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5>Installer Details</h5>
                            <div className="card px-2 py-3 border">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Installer Id
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.installerId}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Installer Type
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.installerType}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Company Name
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.companyName}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Email
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.email}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Address
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.address}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                City
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.city}
                                            </h6>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                State
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.state}
                                            </h6>
                                        </div>
                                    </div>


                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Country
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.country}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Zipcode
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.zipCode}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                                        <div className="form-group">
                                            <p className="font-size-12 font-weight-500 mb-2 text-uppercase">
                                                Phone
                                            </p>
                                            <h6 className="font-weight-500 text-uppercase text-primary font-size-17">
                                                {claimRecord.installerDetails?.phoneNumber}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 px-2 d-flex justify-content-end">
                                <button
                                    className="btn text-white bg-primary mx-1"
                                    onClick={hideModal}
                                >
                                    Back
                                </button>
                                <button
                                    className="btn text-white bg-primary mx-1"
                                    onClick={() => createClaim()}
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default WarrantyClaimAddUpdate;
