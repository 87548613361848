import { Box, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import DataGridContainer from "./common/DataGridContainer";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EquipmentQuoteAddEdit from "./EquipmentQuoteAddEdit";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import {
  fetchClaims,
  fetchEquipments,
  fetchUsage,
  fetchWarranty,
} from "../services/EquipmentService";
import { SelfImprovement } from "@mui/icons-material";
import ClaimDetails from "./ClaimDetails";
import WarrantyClaimAddUpdate from "./WrrantyClaimAddUpdate";
import WarrantyOverView from "./WarrantyOverView";
import { GRID_STYLE } from "./Profile";
import CustomizedSnackbar from "../shared/CustomSnackBar";
const data = [
  {
    id: 0,
    equipmentNumber: "1HL00258",
    maker: "CATERPILLAR",
    serialNumber: "ZMX00507",
    model: "992K",
    installationDate: "29-04-2023",
    address: "KOOLAN IRON ORE PTY LTD, Perth, WA, Australia",
    contract: "STD-18",
    yearOfManufacture: "2021",
    service: "PS75943",
  },
  {
    id: 1,
    equipmentNumber: "1HL00259",
    maker: "CATERPILLAR",
    model: "336D2",
    serialNumber: "ZCT01128",
    installationDate: "15-06-2023",
    address: "KOOLAN IRON ORE PTY LTD, Perth, WA, Australia",
    contract: "STD-13",
    yearOfManufacture: "2021",
    service: "PS75943",
  },
  {
    id: 2,
    equipmentNumber: "1HL00271",
    maker: "CATERPILLAR",
    model: "C15",
    serialNumber: "XP800111",
    installationDate: "23-08-2023",
    address: "KOOLAN IRON ORE PTY LTD, Perth, WA, Australia",
    contract: "STD-14",
    yearOfManufacture: "2021",
    service: "PS75943",
  },
];

const usageData = [
  {
    id: 0,
    currentUsage: "982991123",
    averageUsage: "8 Hour/day",
    updateDate: "01-10-23",
    sensorId: "1200H",
    smuId: "992",
    smuType: "Hour",
  },
  {
    id: 1,
    currentUsage: "982991123",
    averageUsage: "8 Hour/day",
    updateDate: "01-10-23",
    sensorId: "1200H",
    smuId: "992",
    smuType: "Hour",
  },
];

const EquipmentMaster = () => {
  const [modalType, setModalType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showClaimBackModal, setShowClaimBackModal] = useState(false);
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [equipmentData, setEquipmentData] = useState([]);
  const [equipmentUsageData, setEquipmentUsageData] = useState([]);
  const [recordId, setRecordId] = useState("");
  const [warranty, setWarranty] = useState([]);
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };
  const [selectionModel, setSelectionModel] = React.useState([]);
  const handleEquipmentModal = (modalType, edit) => {
    setShowModal(true);
    setEdit(edit);
    setModalType(modalType);
  };
  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [showClaimDetailsModal, setShowClaimDetailsModal] = useState(false);

  // const handleShowClaimAddEditModal = () => {
  //   setShowOverviewModal(!showOverviewModal);
  //   setShowClaimAddEditModal(!showClaimAddEditModal);
  //   // setShowClaimBackModal(!showClaimAddEditModal);
  // };

  const handleShowClaimDetails = () => {
    setShowOverviewModal(!showOverviewModal);
    setShowClaimDetailsModal(!showClaimDetailsModal);
  };
  useEffect(() => {
    fetchEquipmentDetails();
    fetchWarrantyDetails(12);
    // fetchClaimDetails();
  }, []);

  useEffect(() => {
    if (selectionModel?.length > 0) {
      fetchUsage(selectionModel).then((result) => {
        console.log(result);
      });
    }
  }, [selectionModel]);
  const fetchEquipmentDetails = async () => {
    await fetchEquipments("currentClient:101211 AND tenantId:74")
      .then((equipments) => {
        console.log(equipments);
        setEquipmentData(equipments);
      })
      .catch((e) => console.log(e));
  };
  const fetchWarrantyDetails = async (value) => {
    await fetchWarranty(value ? value : 12)
      .then((warranty) => {
        console.log(warranty);
        setWarranty(warranty);
      })
      .catch((e) => console.log(e));
  };

  const equipmentsColumns = [
    {
      field: "maker",
      headerName: "Manufacturer",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <div style={{ fontSize: 12 }}>{params.row.maker}</div>
      ),
    },
    { field: "model", headerName: "Model", flex: 1, minWidth: 120 },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      flex: 1,
      minWidth: 120,
    },

    {
      field: "installationDate", //confirm
      headerName: "Date Of Purchase",
      flex: 1,
      minWidth: 120,
    },
    { field: "address", headerName: "Address", flex: 1, minWidth: 220 }, //not present
    {
      field: "contract",
      headerName: "Contracts/EW",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <>
          <Typography fontSize={12} sx={{ mr: 1 }}>
            {params.value}
          </Typography>
          <GppGoodOutlinedIcon
            className="text-primary"
            onClick={() => {
              fetchWarrantyDetails(
                params.value === "STD-14"
                  ? setRecordId(14)
                  : params.value === "STD-13"
                  ? setRecordId(13)
                  : setRecordId(12)
              );
              setShowWarrantyModal(true);
            }}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
    {
      field: "yearOfManufacture",
      headerName: "Manufacturing Year",
      flex: 1,
      minWidth: 120,
    },
    { field: "service", headerName: "Service Program", flex: 1, minWidth: 100 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <EditOutlinedIcon
                  onClick={() =>
                    handleEquipmentModal("equipmentTableModal", true)
                  }
                />
              </div>
            }
            label="View"
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <div className=" cursor" title="Claim Back">
                <AddCardOutlinedIcon
                  onClick={() => setShowClaimBackModal(true)}
                />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const usageColumns = [
    {
      field: "currentUsage",
      headerName: "Current Usage",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "averageUsage",
      headerName: "Average Usage",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "updateDate",
      headerName: "Last Updated Date",
      flex: 1,
      minWidth: 120,
    },
    { field: "sensorId", headerName: "Sensor ID", flex: 1, minWidth: 120 },

    { field: "smuId", headerName: "SMU ID", flex: 1, minWidth: 120 },
    {
      field: "smuType",
      headerName: "SMU Type",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor">
                <EditOutlinedIcon
                  onClick={() => handleEquipmentModal("usageTableModal", true)}
                />
              </div>
            }
            label="View"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomizedSnackbar
        handleClose={handleSnackBarClose}
        open={openSnack}
        severity={severity}
        message={snackMessage}
      />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mt-4">
            <h4 className="font-weight-600 mb-0">Machines in Possession</h4>
            <a
              className="btn bg-primary text-white"
              onClick={() => handleEquipmentModal("equipmentProfile", false)}
            >
              <span>
                <AddIcon />
              </span>
              Add Equipment
            </a>
          </div>
          <DataGridContainer
            rowsData={data}
            // rowsData={equipmentData}
            columns={equipmentsColumns}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
          />
          <div className="d-flex align-items-center justify-content-between ">
            <h4 className="font-weight-600 mb-0">Usage Details</h4>
            <a
              className="btn bg-primary text-white"
              onClick={() => handleEquipmentModal("usageProfile", false)}
            >
              <span>
                <AddIcon />
              </span>
              Add Usage
            </a>
          </div>
          {/* <DataGridContainer
            // rowsData={equipmentUsageData}
            rowsData={usageData}
            orderData={true}
            columns={usageColumns}
          /> */}
          <Box
            sx={{
              width: "100%",
              height: 500,
              marginTop: 3,
              marginBottom: 5,
              marginInline: 2,
            }}
          >
            <DataGrid
              rows={usageData}
              columns={usageColumns}
              sx={GRID_STYLE}
              rowsPerPageOptions={[10, 20, 50]}
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
      {showModal && (
        <EquipmentQuoteAddEdit
          show={showModal}
          hideModal={() => setShowModal(false)}
          modalType={modalType}
          edit={edit}
        />
      )}
      {showClaimBackModal && (
        <ClaimDetails
          show={showClaimBackModal}
          hideModal={() => setShowClaimBackModal(false)}
          warranty={warranty}
          handleShowClaimDetails={handleShowClaimDetails}
          handleSnack={handleSnack}
        />
      )}
      {showWarrantyModal && (
        <WarrantyOverView
          show={showWarrantyModal}
          hideModal={() => setShowWarrantyModal(false)}
          recordId={recordId}
          warranty={warranty}
          handleSnack={handleSnack}
        />
      )}
    </>
  );
};

export default EquipmentMaster;
