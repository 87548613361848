export const PARTS_ONLY = "partsOnly";
export const SERVICE_ONLY = "serviceOnly";
export const WITH_PARTS = "withParts";
export const CATEGORIES = [
    {
        value: 'CONTRACT_OR_EXTENDED_WARRANTY',
        label: "Maintenance Solutions",
        description: "Our self-service option helps your customers browse products, aftermarket services and solution online."
    },
    {
        value: 'REPAIR_OR_REPLACE',
        label: "Repair Solutions",
        description: "Our platform is built to create templates and kits. It has twin builder functionality."
    },
    {
        value: 'ROUTINE_MAINTENANCE_OR_TASK',
        label: "Routine Maintenance Tasks",
        description: "Our inbuilt price engine helps you price spare parts, labor, miscellaneous, and consumables."
    },
    {
        value: 'NEW_EQUIPMENT_SOLUTION',
        label: "New Equipment Customisation",
        description: "The quotes and follow up orders that your sales reps build using our preconfigured solution."
    }
]
export const SUPPORT_PLANS = [
    { value: 'STANDARD', label: "STANDARD" },
    { value: 'PREMIUM', label: "PREMIUM" },
    { value: 'SUPERIOR', label: "SUPERIOR" },
    { value: 'CUSTOMIZED', label: "CUSTOMIZED" }
]
export const OWL_CAROUSEL_OPTIONS = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    // navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        800: {
            items: 3,
        },
        1200: {
            items: 4,

        }
    },
};


export const claimStatusOptions = [
    { label: "New", value: "NEW" },
    { label: "In Review", value: "IN_REVIEW" },
    { label: "Approved", value: "APPROVED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Closed", value: "CLOSED" },
];

export const claimTypeOptions = [
    { label: "Std", value: "STD" },
    { label: "Extended", value: "EXTENDED" },
    { label: "Goodwill", value: "GOODWILL" },
    { label: "Service Letter", value: "SERVICE_LETTER" },
];

export const claimRequestObj = {
    claimNumber: 0,
    modelNumber: "",
    equipmentNumber: "",
    serialNumber: "",
    componentCode: "",
    claimStatus: "",
    claimType: "",
    failDate: new Date(),
    failurePartNumber: "",
    hourOnMachine: 0,
    hoursOnFailedPart: 0,
    uploadPhoto: "",
    partList: "",
    repairTime: 0,
    claimStory: "",
    claimNotes: "",
    claimQuestionnaire: "",
    payer: "",
    claimApprover: "",
    claimReceiptDate: new Date(),
    createdOn: new Date(),
    updatedOn: new Date(),
    createdDate: new Date(),
    closedDate: new Date(),
    appoverRejectedOn: new Date(),
    warranty: null,
    replacement: true,
};

export const payerOptions = [
    { label: "Customer", value: "CUSTOMER" },
    { label: "Warranty", value: "WARRANTY" },
    { label: "GoodWill", value: "GOODWILL" },
    { label: "Insurer", value: "INSURER" },
];
export const FONT_STYLE = {
    color: "#872ff7",
    fontSize: "17px",
    fontWeight: "500",
    padding: 0,
};
export const FONT_STYLE_SELECT = {
    placeholder: (provided) => ({
        ...provided,
        ...FONT_STYLE,
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: 10,
        boxShadow: 'none'
    }),
    singleValue: (provided) => ({
        ...provided,
        ...FONT_STYLE,
    }),
};