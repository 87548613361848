import axios from "axios";
import Cookies from "js-cookie";

// get the Authorization AuthToken data
export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}

// API Success
const handleSuccess = (result, successCallBack) => {
  if (successCallBack !== null) {
    if (result.status === 403) {
      Cookies.remove("loginTenantDtl");
      Cookies.remove("auxAuthToken");
      window.location.href = "/login";
      return;
    }
    // if(result.status !== 200){
    //     toast.
    //     return;
    // }
    successCallBack(result);
  }
};

const handleFailure = (error, failedCallBack) => {
  if (failedCallBack != null) {
    failedCallBack(error);
    return;
  }
};

export const callGetApi = (loading, url, successCallBack, failureCallBack) => {
  axios
    .get(url, getAccessToken())
    .then((res) => handleSuccess(res, successCallBack))
    .catch((error) => handleFailure(error, failureCallBack));
};

export const callPostApi = (
  loading,
  url,
  requestObj,
  successCallBack,
  failureCallBack
) => {
  axios
    .post(url, requestObj, getAccessToken())
    .then((res) => handleSuccess(res, successCallBack))
    .catch((error) => handleFailure(error, failureCallBack));
};

export const callPutApi = (
  loading,
  url,
  requestObj,
  successCallBack,
  failureCallBack
) => {
  axios
    .put(url, requestObj, getAccessToken())
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => handleFailure(error, failureCallBack));
};

export const callDeleteApi = (
  loading,
  url,
  successCallBack,
  failedCallBack
) => {
  axios
    .delete(url, getAccessToken())
    .then((result) => handleSuccess(result, successCallBack))
    .catch((error) => handleFailure(error, failedCallBack));
};
