import axios from "axios";
import { SYSTEM_ERROR } from "../config/CONSTANTS";
import Cookies from "js-cookie";
import {
  SEARCH_CONSUMABLE,
  SEARCH_CUSTOMER,
  SEARCH_EQUIPMENT,
  SEARCH_EXTWORK,
  SEARCH_MACHINE,
  SEARCH_SPARC_PART,
  SEARCH_SPAREPART,
} from "./CONSTANTS";

/* ----------------- Authorization ------------------- */

export function getAccessToken() {
  let storedCookie = Cookies.get("logincustomerinfo");
  let getCookiesJsonData;
  if (storedCookie != undefined) {
    getCookiesJsonData = JSON.parse(storedCookie);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${getCookiesJsonData?.access_token}`,
    },
  };
  return config;
}

/* ------------------------------------------------------------ */

//Search Customer based on the search criteria to fill the header
export const customerSearch = (searchStr) => {
  console.log("RepairBuilder > customerSearch called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_CUSTOMER(searchStr), getAccessToken())
        .then((res) => {
          console.log("customerSearch > axios res=", res);
          if (res.status === 200) resolve(res.data);
          else reject("Error occurred while fetching customer data");
        })
        .catch((err) => {
          console.log("customerSearch > axios err=", err);
          reject("Error in customerSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > customerSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search machine from equipment master based on the search criteria
export const machineSearch = (searchStr) => {
  console.log("RepairBuilder > machineSearch called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_MACHINE(searchStr), getAccessToken())
        .then((res) => {
          console.log("machineSearch > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("machineSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > machineSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Spare Part based on the search criteria
export const sparePartSearch = async (searchStr) => {
  console.log("RepairBuilder > sparePartSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_SPAREPART(searchStr), getAccessToken())
        .then((res) => {
          console.log("sparePartSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("sparePartSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > sparePartSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getConsumables = (query) => {
  console.log("SearchService > getConsumables called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_CONSUMABLE(query), getAccessToken())
        .then((res) => {
          console.log("getConsumables > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getConsumables > axios err=", err);
          reject("Error in getConsumables axios!");
        });
    } catch (error) {
      console.error("in SearchService > getConsumables, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const getExtWork = (query) => {
  console.log("SearchService > getExtWork called...");
  return new Promise((resolve, reject) => {
    try {
      axios
        .get(SEARCH_EXTWORK(query), getAccessToken())
        .then((res) => {
          console.log("getExtWork > axios res=", res);
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getExtWork > axios err=", err);
          reject("Error in getExtWork axios!");
        });
    } catch (error) {
      console.error("in SearchService > getExtWork, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Equipment based on the search criteria
export const equipmentSearch = async (searchStr) => {
  console.log("searchServices > equipmentSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_EQUIPMENT(searchStr), getAccessToken())
        .then((res) => {
          console.log("equipmentSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("equipmentSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > equipmentSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};

//Search Parts based on the search criteria
export const partsSearch = async (searchStr) => {
  console.log("searchServices > partsSearch called...");
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(SEARCH_SPARC_PART(searchStr), getAccessToken())
        .then((res) => {
          console.log("partsSearch > axios res=", res);
          if (res.status === 200) {
            resolve(res.data);
          } else {
            console.log("Status:", res.status);
            reject("Error in Search Sparepart axios!");
          }
        })
        .catch((err) => {
          console.log("partsSearch > axios err=", err);
          reject("Error in itemSearch axios!");
        });
    } catch (error) {
      console.error("in RepairBuilder > partsSearch, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
