import React, { useContext, useState } from "react";
import CustomizedSnackbar from "../../../shared/CustomSnackBar";
import { PartsContext } from "../../../context/PartsContext";
import { Grid, Typography } from "@mui/material";
import PartCartItem from "./PartCartItem";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

const PartsCart = () => {
  const { cartParts, setCartParts } = useContext(PartsContext);

  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [openQuote, setOpenQuote] = useState(false);
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  async function removeItem(portfolioId) {
    const _cartParts = cartParts.filter((obj) => obj.id !== portfolioId);
    setCartParts(_cartParts);
    handleSnack("success", "Cart item has been removed!");
    // await removeCart(portfolioId)
    //   .then((data) => {
    //     setCartItems(data.data.portfolio);
    //     handleSnack("success", "Cart item has been removed!");
    //   })
    //   .catch((err) => {
    //     handleSnack(
    //       "error",
    //       "Something went wrong while removing the cart item!"
    //     );
    //     console.log(err.message);
    //   });
  }
  return (
    <>
      <CustomizedSnackbar
        handleClose={handleSnackBarClose}
        open={openSnack}
        severity={severity}
        message={snackMessage}
      />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid ">
          <div className="my-4">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="card p-4">
                  <h5 className="mb-0">Shopping Cart</h5>
                  <Grid container>
                    {cartParts.length !== 0 &&
                      cartParts.map((item) => (
                        <Grid item xs={12} md={6} sx={{ p: 4 }}>
                          <PartCartItem
                            key={item.portfolioId}
                            item={item}
                            setCartItems={setCartParts}
                            removeItem={removeItem}
                            handleSnack={handleSnack}
                            cartItems={cartParts}
                          />
                        </Grid>
                      ))}
                    {cartParts.length === 0 && (
                      <Grid item xs={12} md={12} sx={{ paddingTop: 4 }}>
                        <Typography fontSize={16} fontWeight={500}>
                          No items into cart
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </div>
              {cartParts.length !== 0 && (
                <div className="col-md-12 col-sm-12">
                  <div
                    className="card p-4 border"
                    style={{ border: 1, borderColor: "#000" }}
                  >
                    <Grid container xs={12}>
                      <Grid item xs={3} container justifyContent={"center"}>
                        <div>
                          <Typography fontSize={12}>SPARE PARTS</Typography>
                          <Typography fontWeight={500}>
                            {formatter.format(
                              cartParts.reduce((sum, cartItem) => {
                                return (
                                  sum + cartItem.listPrice * cartItem.quantity
                                );
                              }, 0)
                            )}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={2} container justifyContent={"center"}>
                        <div>
                          <Typography fontSize={12}>LABOR CHARGE</Typography>
                          <Typography>
                            $0
                            {/* {formatter.format(
                            cartItems.reduce((sum, cartItem) => {
                              return (
                                sum +
                                cartItem.portfolioPrice.labourPrice *
                                  cartItem.portfolioCart.quantity
                              );
                            }, 0)
                          )} */}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={2} container justifyContent={"center"}>
                        <div>
                          <Typography fontSize={12}>MISC.</Typography>
                          <Typography>
                            $0
                            {/* {formatter.format(
                            cartItems.reduce((sum, cartItem) => {
                              return (
                                sum +
                                cartItem.portfolioPrice.miscPrice *
                                  cartItem.portfolioCart.quantity
                              );
                            }, 0)
                          )} */}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={2} container justifyContent={"center"}>
                        <div>
                          <Typography fontSize={12}>DISCOUNT</Typography>
                          <Typography>
                            $0
                            {/* {formatter.format(
                            cartItems.reduce((sum, cartItem) => {
                              return (
                                sum +
                                cartItem.portfolioPrice.sparePartsPrice *
                                  cartItem.portfolioCart.quantity
                              );
                            }, 0)
                          )} */}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={3} container justifyContent={"center"}>
                        <div>
                          <Typography fontSize={12}>TOTAL</Typography>
                          <Typography
                            className="text-primary"
                            fontSize={18}
                            fontWeight={500}
                          >
                            {formatter.format(
                              cartParts.reduce((sum, cartItem) => {
                                return (
                                  sum + cartItem.listPrice * cartItem.quantity
                                );
                              }, 0)
                            )}
                            {/* {formatter.format(
                            cartItems.reduce((sum, cartItem) => {
                              return (
                                sum +
                                cartItem.portfolioPrice.totalPrice *
                                  cartItem.portfolioCart.quantity
                              );
                            }, 0)
                          )} */}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
            {cartParts.length !== 0 && (
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <a
                    href="/Order"
                    className="btn border-primary text-primary pull-right"
                    style={{ width: "100%" }}
                  >
                    CANCEL
                  </a>
                </div>
                <div className="col-md-6 col-sm-6">
                  <a
                    href={undefined}
                    className="btn bg-primary text-white pull-right"
                    style={{ width: "100%" }}
                    onClick={() => setOpenQuote(true)}
                    //   cartItems={cartItems}
                  >
                    ORDER
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartsCart;
