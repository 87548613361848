import { useContext, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { userLogin } from "../services/authService";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { auth, authActions } = useContext(AuthContext);
  const navigate = useNavigate();
  const [login, setLogin] = useState(true);
  const [signInData, setSignInData] = useState({ emailId: "", password: "" });
  const toggleLoginForm = () => {
    setLogin(!login);
  };
  const handleLogin = async () => {
    try {
      let resp = await userLogin({
        emailId: signInData.emailId,
        password: signInData.password,
      });
      if (resp) {
        let token = resp.accessToken;
        authActions.signIn({
          accessToken: token,
          userId: resp.userId,
          role: resp.roles,
          tenantId: resp.tenantId,
          userEmail: resp.userEmail,
          planName: resp.planName,
          customerId: resp.customerId,
          customerName: resp.customerName,
        });
        navigate("/");
      } else {
        // setErrorMessage("Please enter the correct OTP");
        // setShowMessage(true);
      }
    } catch (error) {
      // setErrorMessage(error);
      // setShowMessage(true);
    }
  };

  // Login Form Content
  const loginForm = () => (
    <div className="row login-form">
      <div className="col-lg-12 col-12">
        <div className="form-group mt-3 login-input-icons">
          <i class="fa fa-user login-icon" />
          <input
            type="email"
            className="form-control login-field border-radius-10"
            placeholder="Email Id"
            name="emailId"
            onChange={(e) =>
              setSignInData({ ...signInData, emailId: e.target.value })
            }
            value={signInData.emailId}
          />
        </div>
      </div>
      <div className="col-lg-12 col-12">
        <div className="form-group  mt-3 login-input-icons">
          <i class="fa fa-lock login-icon" />
          <input
            type={showPassword ? "text" : "password"}
            className="form-control login-field border-radius-10"
            placeholder="Password"
            name="password"
            onChange={(e) =>
              setSignInData({ ...signInData, password: e.target.value })
            }
            value={signInData.password}
          />
          <i
            onClick={() => setShowPassword(!showPassword)}
            className={`fa ${
              showPassword ? "fa-eye-slash" : "fa-eye"
            } eye-icon login-pswd-icon`}
          />
        </div>
      </div>

      <div className="col-12 text-right">
        <a
          href="/forgot-password"
          className="text-decoration-line text-underline-offset"
        >
          Forgot Password?
        </a>
      </div>
      <div className="col-12 mt-4">
        <button
          onClick={handleLogin}
          className="btn w-100 bg-primary text-white"
          style={{ paddingBlock: "12px" }}
        >
          Login
        </button>
        {/* <p className="text-center mt-2">
          Don't have an account?
          <a
            onClick={toggleLoginForm}
            className="text-primary ml-1 font-weight-600 text-decoration-line text-underline-offset cursor"
          >
            Create an account
          </a>
        </p> */}
      </div>
    </div>
  );

  // Sign Up Form Content (create an account)
  const signUpForm = () => (
    <div className="row login-form">
      <div className="col-lg-6 col-md-12 col-sm-6 col-12">
        <div className="form-group">
          <i class="fa fa-user login-icon" />
          <input
            type="name"
            className="form-control border-radius-10"
            placeholder="First Name"
            name="name"
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-12">
        <div className="form-group">
          <i class="fa fa-user login-icon" />
          <input
            type="name"
            className="form-control border-radius-10"
            placeholder="Last Name"
            name="name"
          />
        </div>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="form-group">
          <i class="fa fa-envelope login-icon" />
          <input
            type="email"
            className="form-control border-radius-10"
            placeholder="Enter your email"
            name="email"
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-12">
        <div className="form-group">
          <i class="fa fa-lock login-icon" />
          <input
            type={showPassword ? "text" : "password"}
            className="form-control border-radius-10"
            placeholder="Current Password"
            name="password"
          />
          <i
            onClick={() => setShowPassword(!showPassword)}
            className={`fa ${
              showPassword ? "fa-eye-slash" : "fa-eye"
            } eye-icon signup-pswd-icon`}
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-6 col-12">
        <div className="form-group">
          <i class="fa fa-lock login-icon" />
          <input
            type="password"
            className="form-control border-radius-10"
            placeholder="Confirm Password"
            name="password"
          />
        </div>
      </div>
      <div className="col-12 mt-4">
        <a
          href="#"
          className="btn w-100 bg-primary text-white"
          style={{ paddingBlock: "12px" }}
        >
          Create Account
        </a>
        <p className="text-center mt-2">
          Already have an account?
          <a
            onClick={toggleLoginForm}
            className="text-primary ml-1 font-weight-600 text-decoration-line text-underline-offset cursor"
          >
            Log in
          </a>
        </p>
      </div>
    </div>
  );

  return (
    <div style={{ height: "100vh" }}>
      <div className="d-flex justify-content-between h-100">
        <Grid container className="h-100 bg-white">
          <Grid
            container
            item
            xs={5}
            alignItems={"center"}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <div
              className="bg-violet p-5 h-100 align-items-center"
              style={{ display: "flex" }}
            >
              <div>
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <Typography className="text-white" style={{ fontSize: 45 }}>
                      Welcome to Customer Self-Service Portal.
                    </Typography>
                    <Typography className="text-white" sx={{ my: 3 }}>
                      Login to access your account.
                    </Typography>
                    <img
                      src="../assets/images/Login.svg"
                      alt="login"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container item xs={12} md={7} alignItems={"center"}>
            <div className="bg-white px-5 w-100">
              {/* <h1 className={`text-center font-weight-600 mb-5 text-primary`}> */}
              {/* {login ? ( */}
              {/* <div
                className="col-md-12"
                style={{
                  width: "30%",
                  margin: "auto",
                  paddingBlock: 4,
                }}
              >
                <div>
                  <img src="../assets/images/Logo.svg" alt="login" />
                </div>
              </div> */}
              {/* <div
                className="col-md-12 w-100"
                style={{
                  margin: "auto",
                  paddingBlock: 4,
                  textAlign: "center",
                }}
              >
                <span>
                  Manage your equipment, check warranty, raise claims, find
                  services, and create quotes.
                </span>
              </div> */}

              <div className="row">
                <div
                  className={`col-lg-${login ? 7 : 9} mx-auto col-md-${
                    login ? 8 : 10
                  } col-12`}
                >
                  <div
                    style={{
                      paddingBlock: 4,
                    }}
                  >
                    <div className="pb-4">
                      <img
                        src="../assets/images/Logo.svg"
                        alt="login"
                        style={{ width: "40%", paddingBlock: 7 }}
                      />
                      <Typography style={{ fontSize: 13 }}>
                        Manage your equipment, check warranty, raise claims,
                        find services, and create quotes.
                      </Typography>
                    </div>
                  </div>

                  <Typography>Login to your account</Typography>

                  {login ? loginForm() : signUpForm()}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Login;
