import { Box, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { GRID_STYLE } from "./Profile";
// import penIcon from "../../../assets/images/pen.png";
import CreateIcon from "@mui/icons-material/Create";
import { fetchClaims } from "../services/EquipmentService";
import WarrantyClaimAddUpdate from "./WrrantyClaimAddUpdate";

const ClaimDetails = ({
  show,
  hideModal,
  warranty,
  edit,
  handleSnack,
  // handleShowClaimAddEditModal,
  handleShowClaimDetails,
}) => {
  const [claimsRows, setClaimsRows] = useState([]);
  const [showClaimAddEditModal, setShowClaimAddEditModal] = useState(false);
  const handleShowClaimAddEditModal = () => {
    setShowClaimAddEditModal(!showClaimAddEditModal);
    fetchClaimDetails();
  };
  useEffect(() => {
    fetchClaimDetails();
  }, []);
  const fetchClaimDetails = async () => {
    await fetchClaims()
      .then((claims) => {
        console.log(claims);
        setClaimsRows(claims);
      })
      .catch((e) => console.log(e));
  };
  const claimColumns = [
    {
      field: "claimNumber",
      headerName: "Claim Number",
      flex: 1,
    },
    {
      field: "claimStatus",
      headerName: "Claim Status",
      flex: 1,
    },
    {
      field: "claimType",
      headerName: "Claim Type",
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: "Claim Date",
      flex: 1,
    },
    {
      field: "replacement",
      headerName: "Replacement",
      flex: 1,
      renderCell: (params) => (
        <div style={{ fontWeight: "bold" }}>{params.value ? "Yes" : "No"}</div>
      ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={
              <div className=" cursor" onClick={handleShowClaimDetails}>
                <Tooltip title="Edit">
                  <CreateIcon />
                </Tooltip>
              </div>
            }
            label="Edit"
            className="textPrimary"
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <Modal show={show} onHide={hideModal} size="xl">
      <Modal.Body>
        {showClaimAddEditModal && (
          <WarrantyClaimAddUpdate
            show={showClaimAddEditModal}
            hideModal={handleShowClaimAddEditModal}
            warranty={warranty}
            handleSnack={handleSnack}
          />
        )}
        <h5 style={{ fontWeight: 500 }}>Claim Detials</h5>
        <div className="card px-3">
          {/* <div className="d-flex justify-content-around ">
            <div className="card border px-2 py-2 cursor-pointer">
              <div className="py-4 px-2">
                <span className="">Claim Requested</span>
                <h3 className="mt-0">12</h3>
              </div>
            </div>
            <div className="card border px-2 py-2 cursor-pointer">
              <div className="py-4 px-2">
                <span className="">Claim Accepted</span>
                <h3 className="mt-0">6</h3>
              </div>
            </div>
            <div className="card border px-2 py-2 cursor-pointer">
              <div className="py-4 px-2">
                <span className="">Claim Completed</span>
                <h3 className="mt-0">4</h3>
              </div>
            </div>
            <div className="card border px-2 py-2 cursor-pointer">
              <div className="py-4 px-2">
                <span className="">Claim Rejected</span>
                <h3 className="mt-0">2</h3>
              </div>
            </div>
            <div className="card border px-2 py-2 cursor-pointer">
              <div className="py-4 px-2">
                <span className="">Claim Cancelled</span>
                <h3 className="mt-0">1</h3>
              </div>
            </div>
          </div> */}
          <Box
            sx={{
              width: "100%",
              height: 500,
              marginBottom: 5,
              marginInline: 2,
            }}
          >
            <div className="row align-items-end text-end ">
              <div className="col-12 d-flex justify-content-end mb-2 float-end ">
                <button
                  className="btn btn-primary float-end "
                  onClick={handleShowClaimAddEditModal}
                >
                  Create New
                </button>
              </div>
            </div>
            <DataGrid
              rows={claimsRows}
              columns={claimColumns}
              sx={GRID_STYLE}
              //   initialState={{
              //     pagination: {
              //       paginationModel: {
              //         pageSize: 5,
              //       },
              //     },
              //   }}
              rowsPerPageOptions={[10, 20, 50]}
              //   paginationMode="server"
              disableRowSelectionOnClick
              getRowId={(row) => row.claimId}
            />
          </Box>
        </div>

        {/* <div className="row mt-2 px-2 d-flex justify-content-end">
          <button
            className="btn text-white bg-primary mx-1"
            onClick={hideModal}
          >
            Submit
          </button>
          <button
            className="btn text-white bg-primary mx-1"
            onClick={hideModal}
          >
            Cancel
          </button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default ClaimDetails;
