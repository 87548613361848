import React, { useContext, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import $ from "jquery";
import SelectFilter from "react-select";
import { Link, useNavigate } from "react-router-dom";

import CustomizedSnackbar from "../../../shared/CustomSnackBar";
import { partsSearch } from "../../../services/searchServices";
import { sparePartRequestObj } from "../warrantyManagementConstants";
import { callGetApi } from "../../../services/ApiCaller";
import { Get_Spare_Parts_Datails_By_Id_GET } from "../../../services/CONSTANTS";
import { API_SUCCESS } from "../../../services/ResponseCode";
import { isEmpty } from "../../common/textUtilities";
import { PartsContext } from "../../../context/PartsContext";

const partsSearchOptions = [
  { label: "Part Number", value: "partNumber" },
  { label: "Group Number", value: "groupNumber" },
  { label: "Part Group", value: "partsGroup" },
  { label: "BEC Code", value: "becCode" },
  { label: "ERP Material Group", value: "materialGroup" },
  { label: "ERP Material Number", value: "erpMaterialNumber" },
];

const PartsSearchMaster = () => {
  const { cartParts, setCartParts } = useContext(PartsContext);
  const navigate = useNavigate();
  const [querySearchSelector, setQuerySearchSelector] = useState([
    {
      id: 0,
      selectCategory: "",
      selectOperator: "",
      inputSearch: "",
      selectOptions: [],
      selectedOption: "",
    },
  ]);
  const [searchPartList, setSearchPartList] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [partRecord, setPartRecord] = useState({ ...sparePartRequestObj });
  const [viewDetails, setViewDetails] = useState(false);
  // Snack Bar State
  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  // handle AND || OR operator
  const handleOperator = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectOperator = e;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // select search category
  const handleSearchCategory = (e, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.selectCategory = e;
    obj.inputSearch = "";
    obj.selectOptions = [];
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
  };

  // input search
  const handleInputSearch = (e, i) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[i];
    let searchString = tempArray[i].selectCategory.value + "~" + e.target.value;
    if (tempArray[i].selectCategory.value && e.target.value) {
      partsSearch(searchString)
        .then((res) => {
          obj.selectOptions = res;
          tempArray[i] = obj;
          setQuerySearchSelector([...tempArray]);
          $(`.scrollbar-${i}`).css("display", "block");
        })
        .catch((err) => {
          handleSnack("error", "Error occurred while searching spare parts!");
        });
    } else {
      handleSnack("info", "Please fill search criteria!");
      obj.selectOptions = [];
    }
    obj.inputSearch = e.target.value;
  };

  // select data from search list
  const handleSearchListClick = (e, currentItem, obj1, id) => {
    let tempArray = [...querySearchSelector];
    let obj = tempArray[id];
    obj.inputSearch = currentItem[obj.selectCategory.value];
    obj.selectedOption = currentItem;
    tempArray[id] = obj;
    setQuerySearchSelector([...tempArray]);
    $(`.scrollbar-${id}`).css("display", "none");
  };

  // delete the query search
  const handleDeleteQuerySearch = () => {
    setQuerySearchSelector([
      {
        id: 0,
        selectCategory: "",
        selectOperator: "",
        inputSearch: "",
        selectOptions: [],
        selectedOption: "",
      },
    ]);
  };

  // click on search button
  const handleQuerySearchClick = async () => {
    $(".scrollbar").css("display", "none");
    setSelectedPart(null);
    setViewDetails(false);
    var searchStr = "";
    querySearchSelector.map(function (item, i) {
      if (i === 0 && item.selectCategory.value && item.inputSearch) {
        searchStr =
          item.selectCategory.value +
          ":" +
          encodeURI('"' + item.inputSearch + '"');
      } else if (
        item.selectCategory.value &&
        item.inputSearch &&
        item.selectOperator.value
      ) {
        searchStr =
          searchStr +
          " " +
          item.selectOperator.value +
          " " +
          item.selectCategory.value +
          ":" +
          encodeURI('"' + item.inputSearch + '"');
      }
      return searchStr;
    });

    try {
      if (searchStr) {
        const res = await partsSearch(searchStr);
        setSearchPartList(res);
      } else {
        handleSnack("info", "Please fill the search criteria!");
      }
    } catch (err) {
      handleSnack("error", "Error occurred while fetching spare parts!");
    }
  };

  // view searched parts list cliecked parts details
  const handleViewPartDetails = (id) => {
    setSelectedPart(id);
    const rUrl = `${Get_Spare_Parts_Datails_By_Id_GET}${id}`;
    callGetApi(
      null,
      rUrl,
      (response) => {
        if (response.status === API_SUCCESS) {
          const responseData = response.data;
          setPartRecord(responseData);
          setViewDetails(true);
        } else {
          setViewDetails(false);
        }
      },
      (error) => {
        setViewDetails(false);
      }
    );
  };

  // add item into cart
  const handleAddToCartItem = (row) => {
    const _cartParts = [...cartParts];
    const exists = _cartParts.some((obj) => obj.id === row.id);
    if (!exists) {
      _cartParts.push({ ...row, quantity: 1 });
    } else {
      navigate("/cart");
      //   handleSnack(
      //     "info",
      //     `Part Number ${row.partNumber} already added into cart.`
      //   );
    }
    setCartParts(_cartParts);
  };

  return (
    <>
      <CustomizedSnackbar
        handleClose={handleSnackBarClose}
        open={openSnack}
        severity={severity}
        message={snackMessage}
      />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div className="container-fluid">
          <h4 className="font-weight-600 mb-3 mt-4">Parts Search</h4>
          <p className="mb-1 mt-4 font-size-12">
            Select the search criteria for Parts
          </p>
          <div className="w-100 equipment-select br-bl pb-3">
            <div className="d-flex align-items-center w-100 border-radius-10">
              <div className="d-flex justify-content-between align-items-center border-radius-10">
                <div className="row align-items-center m-0">
                  {querySearchSelector.length !== 0 &&
                    querySearchSelector.map((obj, i) => (
                      <div
                        className={`customselect py-1 d-flex align-items-center mr-3${
                          i > 0 ? " customselect-margin" : ""
                        }`}
                      >
                        {i > 0 && (
                          <SelectFilter
                            isClearable={true}
                            defaultValue={{ label: "And", value: "AND" }}
                            options={[
                              { label: "And", value: "AND", id: i },
                              { label: "OR", value: "OR", id: i },
                            ]}
                            placeholder="And/Or"
                            onChange={(e) => handleOperator(e, i)}
                            value={obj.selectOperator}
                          />
                        )}
                        <div>
                          <SelectFilter
                            // isClearable={true}
                            options={partsSearchOptions}
                            onChange={(e) => handleSearchCategory(e, i)}
                            value={obj.selectCategory}
                          />
                        </div>
                        <div className="customselectsearch pl-2">
                          <SearchIcon className="text-primary" />
                          <input
                            className="custom-input-sleact"
                            type="text"
                            value={obj.inputSearch}
                            placeholder={"Search Parts"}
                            id={"inputSearch-" + i}
                            autoComplete="off"
                            onChange={(e) => handleInputSearch(e, i)}
                          />

                          <ul
                            className={`list-group customselectsearch-list scrollbar scrollbar-${i} style`}
                          >
                            {obj.selectOptions.map((currentItem, j) => (
                              <li
                                className="list-group-item"
                                key={j}
                                onClick={(e) =>
                                  handleSearchListClick(e, currentItem, obj, i)
                                }
                              >
                                {currentItem[obj.selectCategory.value]}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  <div
                    className={`d-flex align-items-center mr-3 ${
                      querySearchSelector.length > 1 ? "add-delete-mt" : ""
                    }`}
                  >
                    <div>
                      <Link
                        to="#"
                        className="btn-sm cursor p-0 font-size-16 mr-2 bg-white text-violet"
                        // style={{ border: "1px solid #872FF7" }}
                      >
                        +{/* <span style={{ color: "white" }}>+</span> */}
                      </Link>
                    </div>
                    <div onClick={handleDeleteQuerySearch}>
                      <Link to="#" className="p-1 bg-white cursor">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z"
                            fill="#872FF7"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5 2.25C7.08579 2.25 6.75 2.58579 6.75 3V4.5C6.75 4.91421 6.41421 5.25 6 5.25C5.58579 5.25 5.25 4.91421 5.25 4.5V3C5.25 1.75736 6.25736 0.75 7.5 0.75H10.5C11.7426 0.75 12.75 1.75736 12.75 3V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V3C11.25 2.58579 10.9142 2.25 10.5 2.25H7.5ZM3.75 3.75C4.16421 3.75 4.5 4.08579 4.5 4.5V15C4.5 15.4142 4.83579 15.75 5.25 15.75H12.75C13.1642 15.75 13.5 15.4142 13.5 15V4.5C13.5 4.08579 13.8358 3.75 14.25 3.75C14.6642 3.75 15 4.08579 15 4.5V15C15 16.2426 13.9926 17.25 12.75 17.25H5.25C4.00736 17.25 3 16.2426 3 15V4.5C3 4.08579 3.33579 3.75 3.75 3.75Z"
                            fill="#872FF7"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z"
                            fill="#872FF7"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z"
                            fill="#872FF7"
                          />
                        </svg>
                        {/* <i
                          className="fa fa-trash fa-lg"
                          style={{ color: "white" }}
                        ></i> */}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <Link
                  className="btn bg-primary text-white cursor"
                  onClick={handleQuerySearchClick}
                >
                  <span className="ml-1">Search</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {searchPartList.length !== 0 && (
              <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 border-50">
                <div className="bg-grey border-radius-10 p-3">
                  <div className="equipment-master-ul">
                    <ul>
                      {searchPartList.map((parts, i) => (
                        <li
                          key={`parts-master-${i}`}
                          className={`cursor ${
                            parts.id === selectedPart ? "active" : ""
                          }`}
                          onClick={() => handleViewPartDetails(parts.id)}
                        >
                          <div className="row position-relative align-items-center">
                            <div className="global-serach-arrow">
                              <ArrowForwardIosIcon className="text-primary font-size-20 mb-0 pb-0" />
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                              <img
                                src="../assets/images/spare-parts-sm.png"
                                alt="jcb"
                                className=" img-fluid"
                              />
                            </div>
                            <div className="col-lg-5 col-md-5 col-5">
                              <h6 className="font-size-12 font-weight-500 text-primary m-0 text-truncate">
                                {parts.partNumber}
                              </h6>
                              <p className="font-size-12 text-light-60 font-weight-500 m-0">
                                {parts.partDescription}
                              </p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                              <div className="d-block pr-3">
                                <h6 className="font-size-12 font-weight-500 text-primary m-0 text-truncate">
                                  {parts.model}
                                </h6>
                                <p className="font-size-12 text-light-60 font-weight-500 m-0">
                                  {parts.manufacturer}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 equipment-master-chart mt-custom">
              {viewDetails && (
                <>
                  <div className="">
                    <div className="bg-white p-3 border-radius-10 ">
                      <div className="d-flex align-items-center justify-content-between equipment-pagination">
                        <h6 className="font-weight-600 mb-0">
                          {!isEmpty(partRecord.partDescription) &&
                            partRecord.partDescription}
                        </h6>
                        <button
                          className="btn bg-primary text-white cursor"
                          onClick={() => handleAddToCartItem(partRecord)}
                        >
                          {cartParts.length !== 0 &&
                          cartParts.some((obj) => obj.id === partRecord.id)
                            ? "Go "
                            : "Add "}{" "}
                          To Cart <AddShoppingCartIcon />
                        </button>
                      </div>
                      <div className="d-block mt-3">
                        <h6 className="text-primary font-weight-600">
                          {!isEmpty(partRecord.partNumber) &&
                            partRecord.partNumber}
                        </h6>
                        <p className="text-light-60 font-size-12 mb-0">
                          {!isEmpty(partRecord.model) && partRecord.model}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white p-3 border-radius-10 overflow-hidden">
                    <div className="row align-items-end">
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            DESCRIPTION
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {`${partRecord.partDescription} ${partRecord.modelGroupDescription}`}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            TYPE
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {isEmpty(partRecord.partType)
                              ? "NA"
                              : partRecord.partType}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <img
                          src="../assets/images/spare-parts.png"
                          alt="spare-parts"
                          className="width-75 img-fluid"
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            MANUFACTURER
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {isEmpty(partRecord.manufacturer)
                              ? "NA"
                              : partRecord.manufacturer}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            MODEL
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {isEmpty(partRecord.model)
                              ? "NA"
                              : partRecord.model}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          GROUP NUMBER
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {isEmpty(partRecord.groupNumber)
                            ? "NA"
                            : partRecord.groupNumber}
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            PARTS GROUP
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {isEmpty(partRecord.partsGroup)
                              ? "NA"
                              : partRecord.partsGroup}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            BEC CODE
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {isEmpty(partRecord.becCode)
                              ? "NA"
                              : partRecord.becCode}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          BEC CODE DESCRIPTION
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {isEmpty(partRecord.becCodeDescription)
                            ? "NA"
                            : partRecord.becCodeDescription}
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            SERIAL NUMBER (IF ANY)
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {isEmpty(partRecord.serialNo)
                              ? "NA"
                              : partRecord.serialNo}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6 col-12 mt-4">
                        <div className="d-block">
                          <p className="text-light-60 font-size-12 m-0 font-weight-500">
                            STATUS
                          </p>
                          <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                            {isEmpty(partRecord.status)
                              ? "NA"
                              : partRecord.status}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="font-weight-500 mt-4">ERP DETAILS</h5>
                  <div className="bg-white p-3 border-radius-10 mt-3 mb-5">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          MATERIAL GROUP
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {/* {isEmpty(partRecord.materialGroup)
                  ? "NA"
                  : partRecord.materialGroup} */}
                          AA: 0S1619
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          MATERIAL NUMBER
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {/* {isEmpty(partRecord.erpMaterialNumber)
                  ? "NA"
                  : partRecord.erpMaterialNumber} */}
                          AA: 0S1619
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          OLD MATERIAL NUMBER
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {/* {isEmpty(partRecord.oldMaterialNumber)
                  ? "NA"
                  : partRecord.manufacturer} */}
                          AA: 0S1619
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          AVERAGE COST
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {/* {isEmpty(partRecord.costPrice)
                  ? 0
                  : partRecord.costPrice} */}
                          $ 90534
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          AVAILABILITY
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {/* {isEmpty(partRecord.availability)
                  ? "NA"
                  : partRecord.availability} */}
                          Stock
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          TOTAL NUMBER AVAILABLE
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {/* {isEmpty(partRecord.totalAvailability)
                  ? "NA"
                  : partRecord.totalAvailability} */}
                          10
                        </p>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                        <p className="text-light-60 font-size-12 m-0 font-weight-500">
                          STATUS
                        </p>
                        <p className="text-primary font-size-12 mt-1 font-weight-500 text-uppercase">
                          {/* {isEmpty(partRecord.status)
                  ? "NA"
                  : partRecord.status} */}
                          Active
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartsSearchMaster;
